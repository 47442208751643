import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0de2eb5e"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Cookie: require('/home/forge/v2.meidenvanholland.nl/components/cookie.vue').default,Header: require('/home/forge/v2.meidenvanholland.nl/components/header/Header.vue').default,Footer: require('/home/forge/v2.meidenvanholland.nl/components/Footer.vue').default,Navigation: require('/home/forge/v2.meidenvanholland.nl/components/Navigation.vue').default})
