import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _15c97095 = () => interopDefault(import('../pages/aanmelden.vue' /* webpackChunkName: "pages/aanmelden" */))
const _44f4f20e = () => interopDefault(import('../pages/direct/index.vue' /* webpackChunkName: "pages/direct/index" */))
const _dc28ae96 = () => interopDefault(import('../pages/genres/index.vue' /* webpackChunkName: "pages/genres/index" */))
const _2e46ed7f = () => interopDefault(import('../pages/gratis-sexfilms/index.vue' /* webpackChunkName: "pages/gratis-sexfilms/index" */))
const _014ef631 = () => interopDefault(import('../pages/modellen/index.vue' /* webpackChunkName: "pages/modellen/index" */))
const _ecddff28 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _797f5746 = () => interopDefault(import('../pages/overzicht/index.vue' /* webpackChunkName: "pages/overzicht/index" */))
const _3c184c53 = () => interopDefault(import('../pages/privefilmpjes/index.vue' /* webpackChunkName: "pages/privefilmpjes/index" */))
const _499dfb1c = () => interopDefault(import('../pages/series/index.vue' /* webpackChunkName: "pages/series/index" */))
const _2078bb24 = () => interopDefault(import('../pages/sexfilms/index.vue' /* webpackChunkName: "pages/sexfilms/index" */))
const _177b27e8 = () => interopDefault(import('../pages/transactie.vue' /* webpackChunkName: "pages/transactie" */))
const _e71f2726 = () => interopDefault(import('../pages/tv-gids/index.vue' /* webpackChunkName: "pages/tv-gids/index" */))
const _41dc6548 = () => interopDefault(import('../pages/webcamsex/index.vue' /* webpackChunkName: "pages/webcamsex/index" */))
const _5bbb78f9 = () => interopDefault(import('../pages/zoekresultaten/index.vue' /* webpackChunkName: "pages/zoekresultaten/index" */))
const _b71b4226 = () => interopDefault(import('../pages/account/direct.vue' /* webpackChunkName: "pages/account/direct" */))
const _6979b936 = () => interopDefault(import('../pages/account/gegevens-voorkeuren.vue' /* webpackChunkName: "pages/account/gegevens-voorkeuren" */))
const _42670448 = () => interopDefault(import('../pages/account/gehuurd.vue' /* webpackChunkName: "pages/account/gehuurd" */))
const _83517216 = () => interopDefault(import('../pages/account/inloggen.vue' /* webpackChunkName: "pages/account/inloggen" */))
const _0df6bbb1 = () => interopDefault(import('../pages/account/mijn-credits.vue' /* webpackChunkName: "pages/account/mijn-credits" */))
const _d425ad88 = () => interopDefault(import('../pages/account/mijn-favorieten.vue' /* webpackChunkName: "pages/account/mijn-favorieten" */))
const _7b2ca804 = () => interopDefault(import('../pages/account/registreren.vue' /* webpackChunkName: "pages/account/registreren" */))
const _723ddfa6 = () => interopDefault(import('../pages/account/wachtwoord-herstellen.vue' /* webpackChunkName: "pages/account/wachtwoord-herstellen" */))
const _1b5ddc91 = () => interopDefault(import('../pages/account/wachtwoord-vergeten.vue' /* webpackChunkName: "pages/account/wachtwoord-vergeten" */))
const _b4b74730 = () => interopDefault(import('../pages/acties/adventskalender/index.vue' /* webpackChunkName: "pages/acties/adventskalender/index" */))
const _b7af668a = () => interopDefault(import('../pages/acties/black-friday-2024/index.vue' /* webpackChunkName: "pages/acties/black-friday-2024/index" */))
const _6bc287f4 = () => interopDefault(import('../pages/acties/droomvrouw-lexa/index.vue' /* webpackChunkName: "pages/acties/droomvrouw-lexa/index" */))
const _63604432 = () => interopDefault(import('../pages/acties/energie-opwekkers/index.vue' /* webpackChunkName: "pages/acties/energie-opwekkers/index" */))
const _706c9830 = () => interopDefault(import('../pages/acties/hollandszaad/index.vue' /* webpackChunkName: "pages/acties/hollandszaad/index" */))
const _2a8eb28e = () => interopDefault(import('../pages/acties/inmeikiesjij/index.vue' /* webpackChunkName: "pages/acties/inmeikiesjij/index" */))
const _7a8a3611 = () => interopDefault(import('../pages/acties/kamasutra-2024/index.vue' /* webpackChunkName: "pages/acties/kamasutra-2024/index" */))
const _2cfaf968 = () => interopDefault(import('../pages/acties/live-meekijken.vue' /* webpackChunkName: "pages/acties/live-meekijken" */))
const _4f0b3e62 = () => interopDefault(import('../pages/acties/live-meekijken/index.vue' /* webpackChunkName: "pages/acties/live-meekijken/index" */))
const _f83155da = () => interopDefault(import('../pages/acties/live-meekijken/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/live-meekijken/aanmeldformulier/index" */))
const _35e8f1d8 = () => interopDefault(import('../pages/acties/live-meekijken/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/live-meekijken/aanmeldformulier/bedankt" */))
const _77df52de = () => interopDefault(import('../pages/acties/parkeerplaatssex/index.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/index" */))
const _4f3e7eaf = () => interopDefault(import('../pages/acties/sexcamper/index.vue' /* webpackChunkName: "pages/acties/sexcamper/index" */))
const _73de579b = () => interopDefault(import('../pages/acties/sexmetkerst.vue' /* webpackChunkName: "pages/acties/sexmetkerst" */))
const _22990444 = () => interopDefault(import('../pages/acties/sexmetkerst/index.vue' /* webpackChunkName: "pages/acties/sexmetkerst/index" */))
const _dc0fd038 = () => interopDefault(import('../pages/acties/sexmetkerst/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/sexmetkerst/aanmeldformulier/index" */))
const _02d44469 = () => interopDefault(import('../pages/acties/sexmetkerst/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/sexmetkerst/aanmeldformulier/bedankt" */))
const _a8aecbc4 = () => interopDefault(import('../pages/acties/valentijn/index.vue' /* webpackChunkName: "pages/acties/valentijn/index" */))
const _39d2f53a = () => interopDefault(import('../pages/acties/webcamsex.vue' /* webpackChunkName: "pages/acties/webcamsex" */))
const _6de5f6d7 = () => interopDefault(import('../pages/acties/xl-deals-2024.vue' /* webpackChunkName: "pages/acties/xl-deals-2024" */))
const _cd129016 = () => interopDefault(import('../pages/betalen/direct.vue' /* webpackChunkName: "pages/betalen/direct" */))
const _f104c024 = () => interopDefault(import('../pages/betalen/huurtegoed.vue' /* webpackChunkName: "pages/betalen/huurtegoed" */))
const _012bdef0 = () => interopDefault(import('../pages/info/algemene-voorwaarden.vue' /* webpackChunkName: "pages/info/algemene-voorwaarden" */))
const _6090dae4 = () => interopDefault(import('../pages/info/contact/index.vue' /* webpackChunkName: "pages/info/contact/index" */))
const _33dd6dd7 = () => interopDefault(import('../pages/info/feedback/index.vue' /* webpackChunkName: "pages/info/feedback/index" */))
const _1c67a8d2 = () => interopDefault(import('../pages/info/nieuwsbrief/index.vue' /* webpackChunkName: "pages/info/nieuwsbrief/index" */))
const _4e1e08ba = () => interopDefault(import('../pages/info/over.vue' /* webpackChunkName: "pages/info/over" */))
const _5d4906ee = () => interopDefault(import('../pages/info/privacy.vue' /* webpackChunkName: "pages/info/privacy" */))
const _6d85c638 = () => interopDefault(import('../pages/info/sex-link-partners.vue' /* webpackChunkName: "pages/info/sex-link-partners" */))
const _8c1f6022 = () => interopDefault(import('../pages/info/tv-zender-ontvangen.vue' /* webpackChunkName: "pages/info/tv-zender-ontvangen" */))
const _42533114 = () => interopDefault(import('../pages/info/uitschrijven/index.vue' /* webpackChunkName: "pages/info/uitschrijven/index" */))
const _3dfedec8 = () => interopDefault(import('../pages/info/veelgestelde-vragen.vue' /* webpackChunkName: "pages/info/veelgestelde-vragen" */))
const _3b39d566 = () => interopDefault(import('../pages/acties/hollandszaad/actie-voorwaarden.vue' /* webpackChunkName: "pages/acties/hollandszaad/actie-voorwaarden" */))
const _bfcf8482 = () => interopDefault(import('../pages/acties/hollandszaad/betaald.vue' /* webpackChunkName: "pages/acties/hollandszaad/betaald" */))
const _667a4da6 = () => interopDefault(import('../pages/acties/hollandszaad/cadeau.vue' /* webpackChunkName: "pages/acties/hollandszaad/cadeau" */))
const _7158fb1f = () => interopDefault(import('../pages/acties/hollandszaad/groeikaart.vue' /* webpackChunkName: "pages/acties/hollandszaad/groeikaart" */))
const _3ed1862c = () => interopDefault(import('../pages/acties/hollandszaad/verstuurd.vue' /* webpackChunkName: "pages/acties/hollandszaad/verstuurd" */))
const _35e20446 = () => interopDefault(import('../pages/acties/inmeikiesjij/gekozen.vue' /* webpackChunkName: "pages/acties/inmeikiesjij/gekozen" */))
const _f55e3a38 = () => interopDefault(import('../pages/acties/parkeerplaatssex/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/aanmeldformulier/index" */))
const _73e6b333 = () => interopDefault(import('../pages/acties/sexcamper/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/sexcamper/aanmeldformulier/index" */))
const _5251f8b8 = () => interopDefault(import('../pages/acties/valentijn/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/valentijn/aanmeldformulier/index" */))
const _e36f712e = () => interopDefault(import('../pages/info/contact/bedankt.vue' /* webpackChunkName: "pages/info/contact/bedankt" */))
const _7e882cc8 = () => interopDefault(import('../pages/info/feedback/bedankt.vue' /* webpackChunkName: "pages/info/feedback/bedankt" */))
const _841b9148 = () => interopDefault(import('../pages/info/nieuwsbrief/bedankt.vue' /* webpackChunkName: "pages/info/nieuwsbrief/bedankt" */))
const _0d6c8572 = () => interopDefault(import('../pages/info/nieuwsbrief/uitschrijven/index.vue' /* webpackChunkName: "pages/info/nieuwsbrief/uitschrijven/index" */))
const _66084d54 = () => interopDefault(import('../pages/info/uitschrijven/uitgeschreven.vue' /* webpackChunkName: "pages/info/uitschrijven/uitgeschreven" */))
const _f9b3612e = () => interopDefault(import('../pages/acties/parkeerplaatssex/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/aanmeldformulier/bedankt" */))
const _238947f8 = () => interopDefault(import('../pages/acties/sexcamper/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/sexcamper/aanmeldformulier/bedankt" */))
const _e8b17fae = () => interopDefault(import('../pages/acties/valentijn/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/valentijn/aanmeldformulier/bedankt" */))
const _8cfb895c = () => interopDefault(import('../pages/info/nieuwsbrief/uitschrijven/uitgeschreven.vue' /* webpackChunkName: "pages/info/nieuwsbrief/uitschrijven/uitgeschreven" */))
const _5cf0c360 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _df95f126 = () => interopDefault(import('../pages/genres/_slug.vue' /* webpackChunkName: "pages/genres/_slug" */))
const _2c904c37 = () => interopDefault(import('../pages/gratis-sexfilms/_slug.vue' /* webpackChunkName: "pages/gratis-sexfilms/_slug" */))
const _00cf562e = () => interopDefault(import('../pages/modellen/_slug.vue' /* webpackChunkName: "pages/modellen/_slug" */))
const _f04b41b8 = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _7cec99d6 = () => interopDefault(import('../pages/overzicht/_slug.vue' /* webpackChunkName: "pages/overzicht/_slug" */))
const _3a61ab0b = () => interopDefault(import('../pages/privefilmpjes/_slug.vue' /* webpackChunkName: "pages/privefilmpjes/_slug" */))
const _47e759d4 = () => interopDefault(import('../pages/series/_slug.vue' /* webpackChunkName: "pages/series/_slug" */))
const _23e5fdb4 = () => interopDefault(import('../pages/sexfilms/_slug.vue' /* webpackChunkName: "pages/sexfilms/_slug" */))
const _15d958d2 = () => interopDefault(import('../pages/webcamsex/_slug/index.vue' /* webpackChunkName: "pages/webcamsex/_slug/index" */))
const _af90fa92 = () => interopDefault(import('../pages/webcamsex/_slug/live.vue' /* webpackChunkName: "pages/webcamsex/_slug/live" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aanmelden",
    component: _15c97095,
    name: "aanmelden"
  }, {
    path: "/direct",
    component: _44f4f20e,
    name: "direct"
  }, {
    path: "/genres",
    component: _dc28ae96,
    name: "genres"
  }, {
    path: "/gratis-sexfilms",
    component: _2e46ed7f,
    name: "gratis-sexfilms"
  }, {
    path: "/modellen",
    component: _014ef631,
    name: "modellen"
  }, {
    path: "/nieuws",
    component: _ecddff28,
    name: "nieuws"
  }, {
    path: "/overzicht",
    component: _797f5746,
    name: "overzicht"
  }, {
    path: "/privefilmpjes",
    component: _3c184c53,
    name: "privefilmpjes"
  }, {
    path: "/series",
    component: _499dfb1c,
    name: "series"
  }, {
    path: "/sexfilms",
    component: _2078bb24,
    name: "sexfilms"
  }, {
    path: "/transactie",
    component: _177b27e8,
    name: "transactie"
  }, {
    path: "/tv-gids",
    component: _e71f2726,
    name: "tv-gids"
  }, {
    path: "/webcamsex",
    component: _41dc6548,
    name: "webcamsex"
  }, {
    path: "/zoekresultaten",
    component: _5bbb78f9,
    name: "zoekresultaten"
  }, {
    path: "/account/direct",
    component: _b71b4226,
    name: "Direct"
  }, {
    path: "/account/gegevens-voorkeuren",
    component: _6979b936,
    name: "Gegevens & voorkeuren"
  }, {
    path: "/account/gehuurd",
    component: _42670448,
    name: "Gehuurd"
  }, {
    path: "/account/inloggen",
    component: _83517216,
    name: "account-inloggen"
  }, {
    path: "/account/mijn-credits",
    component: _0df6bbb1,
    name: "Mijn credits"
  }, {
    path: "/account/mijn-favorieten",
    component: _d425ad88,
    name: "Mijn favorieten"
  }, {
    path: "/account/registreren",
    component: _7b2ca804,
    name: "account-registreren"
  }, {
    path: "/account/wachtwoord-herstellen",
    component: _723ddfa6,
    name: "account-wachtwoord-herstellen"
  }, {
    path: "/account/wachtwoord-vergeten",
    component: _1b5ddc91,
    name: "account-wachtwoord-vergeten"
  }, {
    path: "/acties/adventskalender",
    component: _b4b74730,
    name: "acties-adventskalender"
  }, {
    path: "/acties/black-friday-2024",
    component: _b7af668a,
    name: "acties-black-friday-2024"
  }, {
    path: "/acties/droomvrouw-lexa",
    component: _6bc287f4,
    name: "acties-droomvrouw-lexa"
  }, {
    path: "/acties/energie-opwekkers",
    component: _63604432,
    name: "acties-energie-opwekkers"
  }, {
    path: "/acties/hollandszaad",
    component: _706c9830,
    name: "acties-hollandszaad"
  }, {
    path: "/acties/inmeikiesjij",
    component: _2a8eb28e,
    name: "acties-inmeikiesjij"
  }, {
    path: "/acties/kamasutra-2024",
    component: _7a8a3611,
    name: "acties-kamasutra-2024"
  }, {
    path: "/acties/live-meekijken",
    component: _2cfaf968,
    children: [{
      path: "",
      component: _4f0b3e62,
      name: "acties-live-meekijken"
    }, {
      path: "aanmeldformulier",
      component: _f83155da,
      name: "acties-live-meekijken-aanmeldformulier"
    }, {
      path: "aanmeldformulier/bedankt",
      component: _35e8f1d8,
      name: "acties-live-meekijken-aanmeldformulier-bedankt"
    }]
  }, {
    path: "/acties/parkeerplaatssex",
    component: _77df52de,
    name: "acties-parkeerplaatssex"
  }, {
    path: "/acties/sexcamper",
    component: _4f3e7eaf,
    name: "acties-sexcamper"
  }, {
    path: "/acties/sexmetkerst",
    component: _73de579b,
    children: [{
      path: "",
      component: _22990444,
      name: "acties-sexmetkerst"
    }, {
      path: "aanmeldformulier",
      component: _dc0fd038,
      name: "acties-sexmetkerst-aanmeldformulier"
    }, {
      path: "aanmeldformulier/bedankt",
      component: _02d44469,
      name: "acties-sexmetkerst-aanmeldformulier-bedankt"
    }]
  }, {
    path: "/acties/valentijn",
    component: _a8aecbc4,
    name: "acties-valentijn"
  }, {
    path: "/acties/webcamsex",
    component: _39d2f53a,
    name: "acties-webcamsex"
  }, {
    path: "/acties/xl-deals-2024",
    component: _6de5f6d7,
    name: "acties-xl-deals-2024"
  }, {
    path: "/betalen/direct",
    component: _cd129016,
    name: "betalen-direct"
  }, {
    path: "/betalen/huurtegoed",
    component: _f104c024,
    name: "betalen-huurtegoed"
  }, {
    path: "/info/algemene-voorwaarden",
    component: _012bdef0,
    name: "info-algemene-voorwaarden"
  }, {
    path: "/info/contact",
    component: _6090dae4,
    name: "info-contact"
  }, {
    path: "/info/feedback",
    component: _33dd6dd7,
    name: "info-feedback"
  }, {
    path: "/info/nieuwsbrief",
    component: _1c67a8d2,
    name: "info-nieuwsbrief"
  }, {
    path: "/info/over",
    component: _4e1e08ba,
    name: "info-over"
  }, {
    path: "/info/privacy",
    component: _5d4906ee,
    name: "info-privacy"
  }, {
    path: "/info/sex-link-partners",
    component: _6d85c638,
    name: "info-sex-link-partners"
  }, {
    path: "/info/tv-zender-ontvangen",
    component: _8c1f6022,
    name: "info-tv-zender-ontvangen"
  }, {
    path: "/info/uitschrijven",
    component: _42533114,
    name: "info-uitschrijven"
  }, {
    path: "/info/veelgestelde-vragen",
    component: _3dfedec8,
    name: "info-veelgestelde-vragen"
  }, {
    path: "/acties/hollandszaad/actie-voorwaarden",
    component: _3b39d566,
    name: "acties-hollandszaad-actie-voorwaarden"
  }, {
    path: "/acties/hollandszaad/betaald",
    component: _bfcf8482,
    name: "acties-hollandszaad-betaald"
  }, {
    path: "/acties/hollandszaad/cadeau",
    component: _667a4da6,
    name: "acties-hollandszaad-cadeau"
  }, {
    path: "/acties/hollandszaad/groeikaart",
    component: _7158fb1f,
    name: "acties-hollandszaad-groeikaart"
  }, {
    path: "/acties/hollandszaad/verstuurd",
    component: _3ed1862c,
    name: "acties-hollandszaad-verstuurd"
  }, {
    path: "/acties/inmeikiesjij/gekozen",
    component: _35e20446,
    name: "acties-inmeikiesjij-gekozen"
  }, {
    path: "/acties/parkeerplaatssex/aanmeldformulier",
    component: _f55e3a38,
    name: "acties-parkeerplaatssex-aanmeldformulier"
  }, {
    path: "/acties/sexcamper/aanmeldformulier",
    component: _73e6b333,
    name: "acties-sexcamper-aanmeldformulier"
  }, {
    path: "/acties/valentijn/aanmeldformulier",
    component: _5251f8b8,
    name: "acties-valentijn-aanmeldformulier"
  }, {
    path: "/info/contact/bedankt",
    component: _e36f712e,
    name: "info-contact-bedankt"
  }, {
    path: "/info/feedback/bedankt",
    component: _7e882cc8,
    name: "info-feedback-bedankt"
  }, {
    path: "/info/nieuwsbrief/bedankt",
    component: _841b9148,
    name: "info-nieuwsbrief-bedankt"
  }, {
    path: "/info/nieuwsbrief/uitschrijven",
    component: _0d6c8572,
    name: "info-nieuwsbrief-uitschrijven"
  }, {
    path: "/info/uitschrijven/uitgeschreven",
    component: _66084d54,
    name: "info-uitschrijven-uitgeschreven"
  }, {
    path: "/acties/parkeerplaatssex/aanmeldformulier/bedankt",
    component: _f9b3612e,
    name: "acties-parkeerplaatssex-aanmeldformulier-bedankt"
  }, {
    path: "/acties/sexcamper/aanmeldformulier/bedankt",
    component: _238947f8,
    name: "acties-sexcamper-aanmeldformulier-bedankt"
  }, {
    path: "/acties/valentijn/aanmeldformulier/bedankt",
    component: _e8b17fae,
    name: "acties-valentijn-aanmeldformulier-bedankt"
  }, {
    path: "/info/nieuwsbrief/uitschrijven/uitgeschreven",
    component: _8cfb895c,
    name: "info-nieuwsbrief-uitschrijven-uitgeschreven"
  }, {
    path: "/",
    component: _5cf0c360,
    name: "index"
  }, {
    path: "/genres/:slug",
    component: _df95f126,
    name: "genres-slug"
  }, {
    path: "/gratis-sexfilms/:slug?",
    component: _2c904c37,
    name: "gratis-sexfilms-slug"
  }, {
    path: "/modellen/:slug",
    component: _00cf562e,
    name: "modellen-slug"
  }, {
    path: "/nieuws/:slug",
    component: _f04b41b8,
    name: "nieuws-slug"
  }, {
    path: "/overzicht/:slug",
    component: _7cec99d6,
    name: "overzicht-slug"
  }, {
    path: "/privefilmpjes/:slug",
    component: _3a61ab0b,
    name: "privefilmpjes-slug"
  }, {
    path: "/series/:slug",
    component: _47e759d4,
    name: "series-slug"
  }, {
    path: "/sexfilms/:slug",
    component: _23e5fdb4,
    name: "sexfilms-slug"
  }, {
    path: "/webcamsex/:slug",
    component: _15d958d2,
    name: "webcamsex-slug"
  }, {
    path: "/webcamsex/:slug/live",
    component: _af90fa92,
    name: "webcamsex-slug-live"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
